<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Crusade Type" pageDesc="list of all crusade types"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                   
                                    <!-- <PerPageComponent @perpageValue="perpageval" />

                                    <TableFilter @statusValue="statusval" portall="" />

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton  @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                        

                                                   
                                        <a class="btn btn-outline-success" @click.prevent="crusade_typeAdd" ref="crusade_type_add_ref" data-bs-toggle="modal"  href="#crusade_typeModal" v-if="app_portal=='MAIN'">
                                            <i class="fas fa-plus"></i> Add Crusade Type
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="crusade_type_ref" data-bs-toggle="modal"  href="#crusade_typeModal">
                                            <i class="fas fa-plus"></i> Crusade Type Ref
                                        </a>   

                              

                                        
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" /> -->


                                </div>
                            </div>
                            
                            <div class="card shadow-sm" id="stylized">
                                <div class="card-body">


                             <div class="row">

                               <div class="col">
                              <label>Search Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: search_typeErr}"
                             @change="search_typeVal($event.target.value)"
                            v-model.trim="formData.search_type">
                            <option value="">--Choose An Option--</option>
                            <option value="STATIC">STATIC</option>
                            <option value="DYNAMIC">DYNAMIC</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: search_typeErr}"
                            v-if="search_typeErr"
                          >
                            {{ search_typeErr }}
                          </div>
                      </div>

                      


                          <div class="col">
                                <label><span class="text_error">*</span>Search By</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getSearchBy"   class="form-control select1" v-model="formData.search_by"  :reduce="search_by => search_by.search_by_field_name" label="search_by" @option:selected="search_byVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: search_byErr }"
                            v-if="search_byErr"
                          >
                            {{ search_byErr }}
                          </div>

                      </div>







                   <div class="col">
                                <label><span class="text_error">*</span>Select Column To View</label>
                             <div class="input-group" style="width:100%">
                          

                         <v-select :options="getColumns"   class="form-control select1" v-model="formData.column"  :reduce="column => column.column" label="column_view" @option:selected="columnVal" />

                                                  
                        
                        </div>

                          <div
                            :class="{ text_error: columnErr }"
                            v-if="columnErr"
                          >
                            {{ columnErr }}
                          </div>

                      </div>

         



                     




                    </div>

                          <div class="row">




                    <div class="col">
                        <label><span class="text_error">*</span>Search Value</label>
                        <div class="input-group">
                          

                          <input
                            type="text"
                            class="form-control"
                            :class="{ form_error: search_valueErr}"
                            placeholder="Search Value"
                            @blur="search_valueVal($event.target.value)" 
                            v-model.trim="formData.search_value"
                          />
                         
                        </div>
                         <div
                            :class="{ text_error: search_valueErr  }"
                           v-if="search_valueErr">
                          {{ search_valueErr }} 
                          </div>
                      </div>





                            <div class="col">
                              <label>View Type</label>
                            <div class="input-group">
                          
                          <select
                            class="form-control"
                            :class="{ form_error: view_typeErr}"
                             @change="view_typeVal($event.target.value)"
                            v-model.trim="formData.view_type">
                            <option value="">--Choose An Option--</option>
                            <option value="GRID">GRID</option>
                            <option value="REPORT">REPORT</option>
                          </select>
                          
                          
                        </div>
                        <div
                            :class="{ text_error: view_typeErr}"
                            v-if="view_typeErr"
                          >
                            {{ view_typeErr }}
                          </div><br/>

                            <button type="submit" class="btn btn-primary">Generate Report</button>

                      </div>


              


                          



                          </div>
                                   
                                </div>


                            <!-- Table Goes Here -->

                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  


        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
// import PerPageComponent from "@/components/table/PerPage.vue"
// import TableSearch from "@/components/table/TableSearch"
// import TableFilter from "@/components/table/TableFilter"
// import ActionButton from "@/components/table/ActionButton"
// import SelectAllComponent from "@/components/table/SelectAllComponent"
import axios from "axios"
import FieldValidateService from "@/services/FieldValidateService";

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex';
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants';
import vSelect from 'vue-select';

export default {
  name: "ConvertReportView",
    beforeRouteEnter(to,from,next){
     let user_level = "";
     if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
     const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
     user_level = user_data[0].user_level;
     }
     else{
             const user_data = JSON.parse(localStorage.getItem("user_portal_info"));
             user_level = user_data.user_level;
     }
   
    
    if(user_level=='SUPER ADMIN' || user_level=='SECRETARY' || user_level=='DENOMINATION ADMIN' || user_level=='DENOMINATION SECRETARY' || user_level=='CHURCH ADMIN' || user_level=='CHURCH SECRETARY'){
        next();

    }else{
  
        next("/user-not-authorise"); 
    }
 
    
  },
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    // PerPageComponent,
    // TableSearch,
    // TableFilter,
    // ActionButton,
    // SelectAllComponent,
    vSelect
    // VueTailwindPagination,

  },



  data(){
    return {
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getCrusadeTypeData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        getSearchBy:[],
        getColumns:[],
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        login_user_id:"",
        user_level:"",
        selectPage:false,

         formData:{
            search_type:"",
            search_by:"",
            column:[],
            search_value:"",
            view_type:"",
         },

         search_typeErr:"",
         search_byErr:"",
         columnErr:"",
         search_valueErr:"",
         view_typeErr:"",


    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('crusade_types/all?is_active=' + this.getstatus)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getCrusadeType();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`crusade_types/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(crusade_type_id => crusade_type_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        console.log(response.data.success)
        notificationBox.succNotifications("Deleted Successfully");
         this.getCrusadeType();
       }).catch(error => {
        console.log(error.message)
         notificationBox.errorNotifications(error)
       })


   },



   deleteRecords(){
      axios.delete(`crusade_types/massDestroy/${this.checked}`)
      .then((response) => {
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Crusade Types were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getCrusadeType();

         }
      })
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getCrusadeType();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getCrusadeType(){
   
        axios.get(
            'crusade_types?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&is_active=' + this.getstatus +
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&crusade_type_id=' + this.crusade_type_id 
            )
        .then(response => {

            this.crusade_types = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getCrusadeType();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.getCrusadeType();

    },

    statusval(value){

        this.table_status(value);
        this.getCrusadeType();

    },

 

   
   crusade_typeEdit(crusade_type_id){

    this.crusade_type_id = crusade_type_id;
    this.view_type = "edit";
    this.getCrusadeTypeDataa(crusade_type_id);
    this.$refs.crusade_type_ref.click();

   },




    crusade_typeView(crusade_type_id)
    {

    this.crusade_type_id = crusade_type_id;
    this.view_type = "view";
    this.getCrusadeTypeDataa(crusade_type_id);
    
    this.$refs.crusade_type_ref.click();

   },




      async getCrusadeTypeDataa(crusade_type_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`crusade_types/${crusade_type_id}`);
            this.showLoading(false);
                this.getCrusadeTypeData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    crusade_typeAdd(){

        this.view_type = "add";
        this.$refs.crusade_type_add_ref.click();

     },






     crusade_typeAdded(){
        this.getCrusadeType();
     },





          search_typeVal(value) {

      let max = 10;
      let field = "Search Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.search_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.search_typeErr);
     }else{

           if(this.errorClient.includes(this.search_typeErr))
           {
            let pos = this.errorClient.indexOf(this.search_typeErr);
           this.errorClient.splice(pos,1);
            this.search_typeErr =""; 
           }
          
          

     }
    },






     search_byVal(value){

             let field = "Search By";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.search_byErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.search_byErr);
      }else{
        if(this.errorClient.includes(this.search_byErr))
        {
        let pos = this.errorClient.indexOf(this.search_byErr);
        this.errorClient.splice(pos,1);
        }
        this.search_byErr =""; 
      }

    },





         columnVal(value){

             let field = "Column";

                   if(typeof value == "object" && value!=null)
             {
             value = value.id;
             }

      if(FieldValidateService.reqVal2(value,field)){
        this.columnErr = FieldValidateService.reqVal2(value,field);
        this.errorClient.push(this.columnErr);
      }else{
        if(this.errorClient.includes(this.columnErr))
        {
        let pos = this.errorClient.indexOf(this.columnErr);
        this.errorClient.splice(pos,1);
        }
        this.columnErr =""; 
      }

    },






          search_valueVal(value) {

      let max = 10;
      let field = "Search Value";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.search_valueErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.search_valueErr);
     }else{

           if(this.errorClient.includes(this.search_valueErr))
           {
            let pos = this.errorClient.indexOf(this.search_valueErr);
           this.errorClient.splice(pos,1);
            this.search_valueErr =""; 
           }
          
          

     }
    },







    view_typeVal(value) {

      let max = 10;
      let field = "Value Type";
     
     if(FieldValidateService.reqMaxStringVal(value,field,max))
     {
       this.view_typeErr = FieldValidateService.reqMaxStringVal(value,field,max);
        this.errorClient.push(this.view_typeErr);
     }else{

           if(this.errorClient.includes(this.view_typeErr))
           {
            let pos = this.errorClient.indexOf(this.view_typeErr);
           this.errorClient.splice(pos,1);
            this.view_typeErr =""; 
           }
          
          

     }
    },






  
  },





 async created(){
       
      
this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
//console.log(JSON.parse(localStorage.getItem("user_portal_info")))

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getCrusadeType();

    this.getSearchBy = [
        {
                "search_by_field_name":"ALL",
                "search_by":"ALL",
            },
            {
                "search_by_field_name":"crusade_types.crusade_type",
                "search_by":"CRUSADE TYPE",
            },
            {
                "search_by_field_name":"denominations.denomination",
                "search_by":"DENOMINATION",
            },
            {
                "search_by_field_name":"churches.church",
                "search_by":"CHURCH",
            },
            {
                "search_by_field_name":"converts.fullname",
                "search_by":"FULLNAME",
            },
            {
                "search_by_field_name":"converts.age",
                "search_by":"AGE",
            },
            {
                "search_by_field_name":"age>=",
                "search_by":"AGE>=",
            },
            {
                "search_by_field_name":"age<=",
                "search_by":"AGE<=",
            },
            {
                "search_by_field_name":"converts.gender",
                "search_by":"GENDER",
            },
            {
                "search_by_field_name":"converts.location",
                "search_by":"LOCATION",
            },
            {
                "search_by_field_name":"converts.residence_phone_number",
                "search_by":"RESIDENCE PHONE NUMBER",
            },
            {
                "search_by_field_name":"converts.office_phone_number",
                "search_by":"OFFICE PHONE NUMBER",
            },
            {
                "search_by_field_name":"converts.first_time_decision_for_christ",
                "search_by":"FIRST TIME DECISION FOR CHRIST",
            },
            {
                "search_by_field_name":"converts.best_time_to_visit",
                "search_by":"BEST TIME TO VISIT",
            },
            {
                "search_by_field_name":"converts.convert_needs",
                "search_by":"CONVERT NEEDS",
            },
    ];

    this.getColumns = [
            {
                "column":"crusade_type",
                "column_view":"CRUSADE TYPE",
            },
            {
                "column":"denomination",
                "column_view":"DENOMINATION",
            },
            {
                "column":"church",
                "column_view":"CHURCH",
            },
            {
                "column":"fullname",
                "column_view":"FULLNAME",
            },
            {
                "column":"age",
                "column_view":"AGE",
            },
            {
                "column":"gender",
                "column_view":"GENDER",
            },
            {
                "column":"location",
                "column_view":"LOCATION",
            },
            {
                "column":"residence_phone_number",
                "column_view":"RESIDENCE PHONE NUMBER",
            },
            {
                "column":"office_phone_number",
                "column_view":"OFFICE PHONE NUMBER",
            },
            {
                "column":"first_time_decision_for_christ",
                "column_view":"FIRST TIME DECISION FOR CHRIST",
            },
            {
                "column":"best_time_to_visit",
                "column_view":"BEST TIME TO VISIT",
            },
            {
                "column":"convert_needs",
                "column_view":"CONVERT NEEDS",
            },
    ];
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const crusade_types = JSON.parse(JSON.stringify(this.crusade_types));
            crusade_types.forEach(crusade_type=>{
                this.checked.push(crusade_type.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "crusade_types/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>