<template>
    <div>
        <SideBarComp/>
        <NavBarComp/>
        <div class="content-wrap">
        <div class="main">
            <div class="section__content section__content--p30">
                <div class="container-fluid">  
                    <HeaderComp page="Converts" pageDesc="list of all converts"/>                
                    <section id="main-content">
                        <div class="row ps-3 pe-3">
                            <div class="col-md-12">                               
                                <div class="row">

                                     

                                    <PerPageComponent @perpageValue="perpageval" />

                                    <!-- <TableFilter @statusValue="statusval" portall="" /> -->

                    

                                    <TableSearch @searchValue="searchval" />


                                  
    


                                    <ActionButton @deleteRecords="deleteRecords" :num_checked="checked.length" portall="" />









                                
                                    <div class="col-2 text-end">
                                        <!-- <a class="btn btn-outline-success" @click="userAdd()" data-bs-toggle="modal"  href="#userModal">
                                            <i class="fas fa-plus"></i> Add User
                                        </a>    -->


                                                   
                                        <a class="btn btn-outline-success" @click="convertAdd()" data-bs-toggle="modal"  href="#convertModal">
                                            <i class="fas fa-plus"></i> Add Convert
                                        </a>   


                                           <a style="display:none" class="btn btn-outline-success" ref="convert_ref" data-bs-toggle="modal"  href="#convertModal">
                                            <i class="fas fa-plus"></i> Convert Ref
                                        </a>   

                              

                                          <!-- <a class="btn btn-outline-success" style="display:none" ref="userEditEmployeeProviderModal" data-bs-toggle="modal" href="#userEmployeeProviderModal" >
                                            <i class="fas fa-plus"></i> Link User To Provider Portal
                                        </a>  -->
                                    </div>


                            


                                    <SelectAllComponent @selRecords="selectAllRecords" :num_checked="checked.length" v-if="selectPage" />


                                </div>
                            </div>
                            
                            <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="table-responsive table-responsive-data2">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                               <th scope="col"><input type="checkbox" v-model="selectPage" /></th>
                                                <!-- <th scope="col">#</th> -->
                                                <th scope="col">
                                                <a href="#" @click.prevent="change_sort('converts.fulname')">Fullname</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.fullname'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.fullname'">&darr;</span>
                                                </th>


                                              <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('denominations.denomination')">Denomination</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='denominations.denomination'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='denominations.denomination'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('churches.church')">Church</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='churches.church'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='churches.church'">&darr;</span>
                                                </th>


                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.gender')">Gender</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.gender'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.gender'">&darr;</span>
                                                </th>

                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.residence_phone_number')">Residence / Personal Phone Number</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.residence_phone_number'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.residence_phone_number'">&darr;</span>
                                                </th>


                                                
                                                <th scope="col">
                                                 <a href="#" @click.prevent="change_sort('converts.first_time_decision_for_christ')">First Time Decision For Christ</a>
                                                <span v-if="sort_direction=='desc' && sort_field=='converts.first_time_decision_for_christ'">&uarr;</span>
                                                <span v-if="sort_direction=='asc' && sort_field=='converts.first_time_decision_for_christ'">&darr;</span>
                                                </th>


                                     

                                               


                                                <th scope="col">
                                                Action
                                                </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(cov) in converts" :key="cov.id" :class="isChecked(cov.id) ? 'table-primary' : ''">
                                                <td>
                                                   <input type="checkbox" :value="cov.id"  v-model="checked"/> 
                                                </td>    
                                                <!-- <th scope="row">{{ index + 1}}</th> -->
                                                    <td>{{cov.fullname}}</td>
                                                    <td>{{cov.denomination }}</td>
                                                    <td>{{cov.church }}</td>
                                                    <td>{{cov.gender }}</td>
                                                    <td>{{cov.residence_phone_number }}</td>
                                                    <td>{{cov.first_time_decision_for_christ }}</td>
                                                    <td>
                                                        <div to="/" class="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis position-relative"></i>
                                                        </div>
                                                        <div class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                                                            <li class="d-flex">                                                    
                                                                <div class="btn"  title="edit" @click="convertEdit(cov.id)">
                                                                    <i class="fa-solid fa-pencil"></i>
                                                                </div>                                                  
                                                                <div onclick="confirm('Are You Sure You Want To Delete This Record?') || event.stopImmediatePropagation()" class="btn" title="delete" @click="deleteSingleRecord(cov.id)">
                                                                    <i class="fa-solid fa-trash"></i>
                                                                </div>                                                  
                                                                <a class="btn" title="view" @click="convertView(cov.id)">
                                                                    <i class="fa-solid fa-eye"></i>
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-4">
                                             <VueTailwindPagination
                                            :current="currentPage"
                                            :total="total"
                                            :per-page="perpage"
                                            @page-changed="pageChange($event)"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterComp/>
                </div>
            </div>
        </div>
        </div> 

  

        <ConvertModals @convertAdded="convertAdded" :convert_view_type="view_type" :convert_data="getConvertData"  />

        <!-- <UserModals @userAdded="userAdded"  @provider_view_typee="provider_view_typee"   @country_view_typee="country_view_typee"  :getUserPortalData="getUserPortalData"   :view_type="view_type"  />

        <EmployeeUserCountry @userView="userView" :employee_country_view_type="country_view_type" :employee_user_id="employee_user_id"  />    
  
        <EmployeeUserProvider @userView="userView"   :employee_provider_view_type="provider_view_type" :employee_provider_user_id="employee_provider_user_id" />      -->
        <!-- Modal -->
        <!-- <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div> -->
    </div>
</template>


<script>
// @ is an alias to /src
import HeaderComp from "@/components/HeaderComp.vue"
import FooterComp from "@/components/FooterComp.vue"
import SideBarComp from "@/components/SideBarComp.vue"
import NavBarComp from "@/components/NavBarComp.vue"
import PerPageComponent from "@/components/table/PerPage.vue"
import TableSearch from "@/components/table/TableSearch"
import ActionButton from "@/components/table/ActionButton"
import SelectAllComponent from "@/components/table/SelectAllComponent"
import ConvertModals from "@/components/modalForms/Convert.vue"
import axios from "axios"

//import "@ocrv/vue-tailwind-pagination/dist/style.css";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";
import "@ocrv/vue-tailwind-pagination/styles";
import notificationBox from '@/services/notificationBox'
import {BASEURL} from '@/services/UserService';
import {  mapMutations, mapState } from 'vuex'
import { LOADING, TABLE_CURRENT_PAGE, TABLE_PER_PAGE, TABLE_SEARCH, TABLE_SEL_ALL, TABLE_SORT_FIELD_DIRECTION, TABLE_STATUS, TABLE_TOTAL, TABLE_URL } from '@/store/storeconstants'


export default {
  name: "ConvertView",
  components: {
    HeaderComp, 
    FooterComp,
    SideBarComp, 
    NavBarComp,
    PerPageComponent,
    TableSearch,
    ActionButton,
    SelectAllComponent,
    VueTailwindPagination,
    ConvertModals

  },



  data(){
    return {
        converts:{},
        //showUserModals:false,

        //currentPage:1,
        //total:100,
        perPage:20,
        //perPage:20,
        search:"",
        is_active: "",
        checked:[],
        user_table_data:{},
        user_portal_table_data:{},
        getConvertData:{},
        view_type:"",
        app_portal:"",
        user_portal_info:{},
        // provider_id:"",
        // country_view_type:"",
        // provider_view_type:"", 
        // employee_user_id:"",
        // employee_provider_user_id:"",
        // support_id:"",
        // dependent_id:"",
        // getSupportData:{},


        convert_id:"",
        login_user_id:"",
        user_level:"",
        crusade_type_id:"",
        selectPage:false,
        //selectAll:false,
     //   sort_direction: 'desc',
     //   sort_field: 'fullname',
      //  url:'',

    }
  },


  computed:{
    ...mapState('dataTable',['currentPage','url','total','perpage','getstatus','getsearch','selectAll','sort_direction','sort_field']),
    ...mapState("user_portal",['search_user_portal_id_result']),
    //...mapState("user",['search_user_id_result'])
  },



  methods:{

       ...mapMutations({
       table_select_all: 'dataTable/'+TABLE_SEL_ALL,
       table_sort_field_direction: 'dataTable/'+TABLE_SORT_FIELD_DIRECTION,
       table_current_page:'dataTable/'+TABLE_CURRENT_PAGE,
       table_url:'dataTable/'+TABLE_URL,
       table_per_page :'dataTable/'+TABLE_PER_PAGE,
       table_total :'dataTable/'+TABLE_TOTAL,
       table_search :'dataTable/'+TABLE_SEARCH,
       table_status :'dataTable/'+TABLE_STATUS,
       showLoading:LOADING,

     //  verified_username:'auth/'+SUCC_AUTH_VERIFY,
   }),




    selectAllRecords(){

        axios.get('converts/all?portal=' + this.app_portal)
             .then(response => {
                 console.log(response.data);
                  this.checked = response.data;
                  // this.selectAll = true;
                 // this.table_checked(response.data);
                   this.table_select_all(true);
             });
    },



    change_sort(field){
        // if(this.sort_field == field){
        //  this.sort_direction =   this.sort_direction == "asc" ? "desc" : "asc"
        // }else{
        //     this.sort_field = field;
        // }
        this.table_sort_field_direction(field);
        this.getConvert();

    },



   deleteSingleRecord(id){

    //console.log(id);

       axios.delete(`converts/${id}`)
       .then( (response) => {
        this.checked = this.checked.filter(convert_id => convert_id != id)
        //this.table_checked(this.checked.filter(user_portal_id => user_portal_id != id))
        //console.log(response.data.success)
        
        notificationBox.succNotifications(response.data.success);
         this.getConvert();
       }).catch(error => {
        //console.log(error.message)
         notificationBox.errorNotifications(error.response.data.error)
       })


   },



   deleteRecords(){
    try{
        console.log(this.checked);
      let response = axios.delete(`converts/massDestroy/${this.checked}`)
console.log(response);
         if(response.status == 204){
            console.log(response.data.success)
            notificationBox.succNotifications("Selected Convert were Deleted Successfully");
            this.checked = [];
            //this.table_checked([])
            this.getConvert();

         }
      }catch(error) {
        console.log(error);
         notificationBox.errorNotifications(error.response.data.error)
       }
   },





   


    pageChange(pageNumber){

        //this.currentPage = pageNumber;
        this.table_current_page(pageNumber);
        this.getConvert();

    },




   isChecked(id){

    return  this.checked.includes(id);

   },



    getConvert(){
   
        axios.get(
            'converts?page=' + this.currentPage + 
            '&page_size=' + this.perpage + 
            '&q=' +this.getsearch + 
            '&sort_direction=' + this.sort_direction +
            '&sort_field=' + this.sort_field +
            '&portal=' + this.app_portal +
            '&convert_id=' + this.convert_id + 
            '&denomination_id=' + this.denomination_id + 
            '&crusade_type_id=' + this.crusade_type_id + 
            '&church_id=' + this.church_id  
            )
        .then(response => {

            this.converts = response.data.data.data;
            //this.perPage = response.data.data.per_page;
               this.table_per_page(response.data.data.per_page);
            //this.total = response.data.data.total;
             this.table_total(response.data.data.total)
        });

    },



    perpageval(value){
        this.table_per_page(value);
       this.getConvert();

    },


    // banSucc(){

    //     this.getSupportMember();

    // },


    searchval(value){

         this.table_search(value);
        this.getConvert();

    },

    // statusval(value){

    //     this.table_status(value);
    //     this.getCrusadeType();

    // },

 

   
   convertEdit(convert_id){

    this.convert_id = convert_id;
    this.view_type = "edit";
    this.getConvertDataa(convert_id);
    this.$refs.convert_ref.click();

   },




    convertView(convert_id)
    {

    this.convert_id = convert_id;
    this.view_type = "view";
    this.getConvertDataa(convert_id);
    
    this.$refs.convert_ref.click();

   },




      async getConvertDataa(convert_id){
         
             this.showLoading(true);
             try{
            //   console.log("From Watchers",user_portal_id);
            //   console.log("From Props",this.get_user_portal_id);
            
            const response = await axios.get(`converts/${convert_id}`);
            this.showLoading(false);
                this.getConvertData = response.data.data;
             }catch(error){
                this.showLoading(false);
             }
          
              },





    convertAdd(){

        this.view_type = "add";

     },






     convertAdded(){
        this.getConvert();
     },




  
  },





 async created(){


     try{
       let response = await axios.get("getActiveCrusadeType");
     // console.log(response.data.data);
     // response.data.data.unshift({id:"",country:"Please Select Option", created_at:null,updated_at:null})   
        this.crusade_type_id =  JSON.parse(JSON.stringify(response.data.data));
        //console.log("meeeeeeee");
    
      
    }catch(error){
        console.log(error);
    }

       
if(Array.isArray(JSON.parse(localStorage.getItem("user_portal_info")))) {
this.app_portal = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_denomination_id;
this.church_id = JSON.parse(localStorage.getItem("user_portal_info"))[0].portal_church_id;

}
else{

    this.app_portal = JSON.parse(localStorage.getItem("user_portal_info")).portal;
this.denomination_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_denomination_id;
this.church_id = JSON.parse(localStorage.getItem("user_portal_info")).portal_church_id;

}

    //this.currentPage = 1;
    this.table_current_page(1);
    this.getConvert();
  },


  watch:{


     "selectPage"(value){
         this.checked = [];
       //  this.table_checked([]);

        if(value){
            const converts = JSON.parse(JSON.stringify(this.converts));
            converts.forEach(convert=>{
                this.checked.push(convert.id)
                //this.table_checked_push(user.id);
            });
        
        }else{
              
              this.checked = [];
              //this.table_checked([]);
              //this.selectAll = false;
              this.table_select_all(false);
        }

     },

     "checked"(){

        this.table_url(BASEURL + "converts/export/"+ this.checked);
       // this.url = BASEURL + this.url + this.checked;

     }

  }

};
</script>