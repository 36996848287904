<template>
  <div class="row">
    
     <div class="alert alert-danger" style="position:fixed; z-index:99999" role="alert" v-if="IDLE_TIMEOUT <= 60"><i class="fa fa-exclamation-circle"></i> <b>Warning: </b> Count Down To <b>{{ countdown  }}</b> To Logout</div>

      <div class="col-lg-8 p-r-0 title-margin-right">
          <div class="page-header">
              <div class="page-title">
                  <h1>{{ page }}, 
                      <span>{{ pageDesc }}</span>
                  </h1>

              </div>
          </div>
      </div>
      <!-- /# column -->
      <div class="col-lg-4 p-l-0 title-margin-left">
          <div class="page-header">
              <div class="page-title">
                  <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                          <router-link to="/">Home</router-link>
                      </li>
                      <li class="breadcrumb-item active">{{ page }}</li>
                  </ol>
              </div>
          </div>
      </div>
      <!-- /# column -->
  </div>
  <!-- /# row-->
</template>

<script>
//import axios from 'axios';
//import notificationBox from "@/services/notificationBox";
export default {
  name: "HeaderComp",
  props: {
    page: String,
    pageDesc: String
  },
  data(){
    return{
    //    IDLE_TIMEOUT :900,
    //    idleSecondsTimer:0,
    //   // idleSecondsCounter:0,
    //    countdown:null
    }
  },


  mounted(){
    // document.onclick = () => {
    //     this.IDLE_TIMEOUT = 900;
    // };

    // document.onmousemove = () => {
    //     this.IDLE_TIMEOUT = 900;
    // };

    // document.onkeypress = () => {
    //     this.IDLE_TIMEOUT = 900;
    // };

    // this.idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
     // notificationBox.warnNotifications(`Count Down To ${this.countdown} seconds to logout`);

  },


  created(){
    //notificationBox.warnNotifications(`Count Down To ${this.countdown} seconds to logout`);
  },





  methods: {
//    async CheckIdleTime(){
//         this.IDLE_TIMEOUT--;
//        this.changes = this.IDLE_TIMEOUT;

       

          
    
//         if(this.IDLE_TIMEOUT == 0){
//             window.clearInterval(this.idleSecondsTimer);

//              try{

//         let response = await axios.post("logout");
//         if(response.status == 200)
//         { 

//         localStorage.clear();
//          this.$router.push("/")

//         }

//       }catch(_){

//      //notificationBox.errorNotifications("Could Not Logout");

//       }
//         }
//     }
  },



    computed:{

        // changes : {
        //     get : function(){
        //         return this.countdown;
        //     },
        //     set : function(v){
        //         this.countdown =  v;
        //     }
        // }
    },


};
</script>

<style lang="scss" scoped></style>